












import { defineComponent } from '@nuxtjs/composition-api';
import SideBar from '~/components/organisms/SideBar/SideBar.vue';
import { useUiState, useCartSidebars } from '~/composables';
import ComplementaryProductsInner from
  '~/components/organisms/ComplementaryProducts/ComplementaryProductsInner/ComplementaryProductsInner.vue';

export default defineComponent({
  name: 'ComplementaryProductsSidebar',
  components: {
    SideBar,
    ComplementaryProductsInner,
  },
  setup() {
    const { isComplementarySidebarOpen } = useUiState();
    const { closeComplementarySidebar } = useCartSidebars();

    const closeSidebar = (shouldOpenCartSidebar: boolean = true) => {
      closeComplementarySidebar(shouldOpenCartSidebar);
    };

    return {
      isComplementarySidebarOpen,
      closeSidebar,
    };
  },
});
